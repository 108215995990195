import React from 'react';
const { Rate } = require('antd');
import styles from '@buddieshr/styles/components/rated.module.scss';
import Image from 'next/image';
import { G2_LINK } from '@buddieshr/utils/constants';
import { useTranslation } from 'react-i18next';

const Rated = ({ bordered = false }) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.rated}${bordered ? ` ${styles.bordered}` : ''}`}>
      <div style={{ display: 'flex' }}>
        <Image
          className={styles.image}
          src="/img/g2.png"
          alt="g2 logo"
          width={38}
          height={38}
        />
      </div>

      <div className={styles.rate}>
        <Rate value={5} disabled size="small" />
        <a
          href={G2_LINK}
          title="best employee engagement software for slack"
          target="_blank"
        >
          <div className={styles.ratedText}>{t('rated', { rates: 100 })}</div>
        </a>
      </div>
    </div>
  );
};
export default Rated;
