/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import { useRouter } from "next/router";
import Benefits from "@buddieshr/components/benefits";
import Footer from "@buddieshr/components/footer";
import Header from "@buddieshr/components/header";
import HowItWorks from "@buddieshr/components/billy/howItWorks";
import MetaHeader from "@buddieshr/components/metaHeader";
import ReadyForBilly from "@buddieshr/components/billy/readyForBilly";
import { useTranslation } from "react-i18next";
import ReviewSnippet from "@buddieshr/components/reviewSnippet";
import WatchADemoButton from "@buddieshr/components/billy/watchADemoButton";
import Rated from "@buddieshr/components/rated";
import FeatureTags from "@buddieshr/components/billy/featureTags";
import styles from "@buddieshr/styles/Home_Billy.module.scss";
import TrustedBy from "@buddieshr/components/trustedBy";
import Features from "@buddieshr/components/app/features";
import AddToSlackButton from "@buddieshr/components/addToSlackButton";
import { PRODUCTS } from "@buddieshr/utils/constants-products";
import Reviews from "@buddieshr/components/billy/reviews";
import { Tag } from "antd";
import BuddieshrAppList from "@buddieshr/components/buddieshrAppList";
import LogoApp from "@buddieshr/components/app/logoApp";
import PageWrapper from "@buddieshr/components/templates/pageWrapper";

export default function Home(pageProps) {
  const { t } = useTranslation();
  const router = useRouter();
  const app = PRODUCTS.find((c) => c.id === "Billy");


  return (
    <>
      <MetaHeader
        title={t("metaTitleBilly")}
        description={t("descriptionContentBilly")}
        currentURL={`https://buddieshr.com/${
          router.locale === "en" ? "" : router.locale
        }/${app.slug}`}
        currentShare={`https://buddieshr.com/img/apps/${app.slug}/share_preview_1.png`}
        pageSlug={`${app.slug}`}
        locale={router.locale}
      />
      {/* <MarketingBanner link={link} /> */}
      <div className={styles.container}>
        <Header
          app={app}
        />
        <main
          className={styles.main}
          style={{ background: app.backgroundColor }}
        >
          <ReviewSnippet />
          <PageWrapper width={1500}>
            <div className={styles.firstSectionWrapper}>
              {/* <!-- Main title --> */}
              <LogoApp
                app={app}
                removeBack
                withText
                withSubtitle
                removeByBuddies
                wrapperStyle={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 36,
                  maxWidth: "100%",
                }}
                style={{
                  textAlign: "left",
                  margin: "auto",
                  border: "2px solid #0000000a",
                  borderRadius: "10px",
                  padding: "6px 12px",
                  paddingBottom: "8px",
                  transform: "scale(1.2)",
                  maxWidth: "calc(100% - 40px)",
                }}
              />
              <div className={styles.tagWrapper}>
                <Tag color="purple" className={styles.tag}>
                  CELEBRATE EMPLOYEES
                </Tag>
              </div>
              <br />
              <div className={styles.Main_title_wrapper}>
                <h1 className={`${styles.Main_title} ${styles.h1}`}>
                  {t("whyUse_neverforget_1")}
                  {t("whyUse_neverforget_2")}
                </h1>
                <div className={styles.Main_subtitle}>
                  Billy <span className={styles.bold}>automates</span> birthday
                  and work anniversary{" "}
                  <span className={styles.bold}>celebrations</span> in{" "}
                  <span className={styles.bold}>Slack</span>{" "}
                  <Image
                    src="/img/slack_80.png"
                    alt="slack"
                    width={20}
                    height={20}
                    style={{
                      marginLeft: "6px",
                      marginRight: "6px",
                    }}
                  />
                </div>
                <div className={styles.Main_CTA_wrapper}>
                  <div>
                    <WatchADemoButton app={app} />
                  </div>
                  <div className={styles.Main_CTA_inner}>
                    <AddToSlackButton
                      app={app}
                      id="CTA_top"
                      text="Install Billy for free"
                      style={{ width: "auto" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 26,
                  }}
                >
                  <Rated />
                </div>
                <FeatureTags />

                <div className={styles.Screens_wrapper}>
                  <div className={styles.Screen_capture_1}>
                    <div className={styles.Screens_icon}>
                      <picture>
                        <source
                          srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/1f388/512.webp"
                          type="image/webp"
                        />
                        <Image
                          src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f388/512.gif"
                          alt="🎈"
                          width="122"
                          height="122"
                        />
                      </picture>
                    </div>
                    <Image
                      src="/img/apps/billy/preview-classic.png"
                      alt="celebrate birthday in Slack"
                      title="celebrate birthday in Slack"
                      className={styles.Screen_capture}
                      width={394}
                      height={201}
                      style={{ height: "auto" }}
                    />
                  </div>
                  <div className={styles.Screen_capture_2}>
                    <div className={styles.Screens_icon_2}>
                      <picture>
                        <source
                          srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/1f44f/512.webp"
                          type="image/webp"
                        />
                        <Image
                          src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f44f/512.gif"
                          alt="👏"
                          width="112"
                          height="112"
                        />
                      </picture>
                    </div>
                    <Image
                      src="/img/apps/billy/preview-format.png"
                      alt="celebrate birthday in Slack"
                      title="celebrate birthday in Slack"
                      className={styles.Screen_capture}
                      width={333}
                      height={326}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`${styles.Main_title_decoration1} ${styles.Deco}`}
              >
                <Image
                  src="/img/apps/billy/decorations/baloons.png"
                  alt="baloons"
                  title="baloons"
                  width={140}
                  height={140}
                />
              </div>
              <div
                className={`${styles.Main_title_decoration2} ${styles.Deco}`}
              >
                <Image
                  src="/img/apps/billy/decorations/fiesta.png"
                  alt="fiesta"
                  title="fiesta"
                  width={100}
                  height={100}
                />
              </div>
              <div
                className={`${styles.Main_title_decoration3} ${styles.Deco}`}
              >
                <Image
                  src="/img/apps/billy/decorations/hat.png"
                  alt="Gift"
                  title="Gift"
                  width={200}
                  height={200}
                />
              </div>
              {/* <div className={styles.Main_title_decoration4 Deco}>
              <Image
                src="/img/decorations/hat.png"
                alt="decoration"
                title="decoration"
                width={250}
                height={250}
              />
            </div> */}
            </div>
          </PageWrapper>
          <TrustedBy />
          <HowItWorks />
          <Features app={app} />
          <Reviews />
          <Benefits app={app} />
          <ReadyForBilly app={app} />
          <div>
            <BuddieshrAppList appToHide={app.slug} />
          </div>
        </main>
        <Footer app={app} />
      </div>
    </>
  );
}
